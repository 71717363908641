<template>
  <div class="flex flex-wrap justify-between gap-4 p-4">
    <div class="flex flex-wrap items-center">
      <span
        class="mr-2 text-base font-black uppercase sm:text-lg md:text-xl lg:text-3xl"
      >
        {{ search.category_selection.label }}:
      </span>
      <FieldDropdown
        v-model="model.category"
        class="size-fit lg:w-80"
        :options="search.category_selection.options"
        @update:model-value="$emit('search')"
      />
    </div>
    <div class="flex h-fit flex-wrap items-center">
      <Headline
        class="mr-2 font-black"
        size="3XL"
        :title="search.search_input_field.label + ':'"
      />
      <FieldGenericInput
        v-model="model.search"
        class="mr-2 p-1 md:p-3"
        :placeholder="search.search_input_field.placeholder"
        :type="search.search_input_field.type"
      />
      <G7Button
        class="h-field px-2"
        :label="search.action_button.label"
        @click="$emit('search')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { LabelValue } from "~~/types/form";
import type { MagazinSearch } from "~~/types/magazin";

interface SearchInterface {
  search: string;
  category: LabelValue;
}
defineProps<{
  search: MagazinSearch;
}>();

const model = defineModel<SearchInterface>({
  required: true,
});

defineEmits(["update:modelValue", "search"]);
</script>
